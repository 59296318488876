import type {RuleResult, TypeGuardContext} from '../types';
import {createResult} from '../utilities/createResult';

export interface NumericOptions {
  message?: string;
}

export const numeric = (options: NumericOptions = {}) =>
  function numeric<Value extends string>({value}: TypeGuardContext<Value>): RuleResult<Value> {
    return createResult({
      value,
      valid: /^[0-9]+$/.test(value),
      message: options.message ?? 'The attribute must be a valid UUID.',
    });
  };
