import type {Checkable} from '../types';
import {createResult} from '../utilities/createResult';
import {typeGuard} from '../utilities/typeGuard';

export function DateGuard<Type extends Date>(...rules: Checkable<Type>[]) {
  const guard = typeGuard<Type>(rules);

  guard.check = function isDate(data) {
    const {value} = data;
    let valid = value instanceof Date;
    if (['string', 'number'].includes(typeof value)) {
      valid = new Date(value).toString() !== 'Invalid Date';
    }

    data.value = value instanceof Date ? value : (new Date(value) as Type);
    return createResult({
      valid,
      value: data.value,
      message: this.message() ?? `Expected Date, got ${typeof value}`,
    });
  };

  return guard;
}
