import {generateRandomString} from './generateRandomString';

export const PROTOCOL = 'gid:';
export const NAMESPACE = 'Cindedi';

export function generateGid(domain: string, entity: string): string {
  return `${PROTOCOL}//${NAMESPACE}/${domain}/${entity}/${generateRandomString()}`;
}

export function parseGid(gid: string) {
  if (URL.canParse(gid)) {
    const url = new URL(gid);

    if (url.protocol === PROTOCOL) {
      const [domain, entity, id] = url.pathname.split('/').slice(-3);
      return {domain, entity, id};
    }
  }

  throw new Error('Invalid GID');
}
