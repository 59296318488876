import {record, string} from '@cindedi/validation';
import { numeric } from '@cindedi/validation/rules/numeric';

export const PublicFileDto = record<FileSystem.PublicFileDto>({
  publicId: string(),
  name: string(),
  extension: string(),
  size: string(numeric()),
  url: string(),
});
