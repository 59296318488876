import {CindediError} from '@cindedi/error';
import type {Cindedi} from '@cindedi/spec/error';

export class ValidationError extends CindediError implements Cindedi.Error {
  list: Cindedi.ErrorMessage[];

  get hasAny(): boolean {
    return this.list.length > 0;
  }

  constructor(message?: Cindedi.ErrorMessage) {
    super(
      message ?? {
        status: 422,
        title: 'Invalid data',
      },
    );
    this.list = message ? [message] : [];
    Object.setPrototypeOf(this, ValidationError.prototype);
  }

  push(message: Partial<Cindedi.ErrorMessage>): void {
    this.list.push({
      status: 422,
      title: 'Invalid data',
      ...message,
    });
  }

  serialize(): Cindedi.ErrorMessage[] {
    return this.list.slice();
  }
}
