import {parseGid} from '@cindedi/utilities/gid';
import type {RuleResult, TypeGuardContext} from '../types';
import {createResult} from '../utilities/createResult';

export interface CindediGidOptions {
  message?: string;
  domain?: string;
  entity?: string;
}

export const cindediGid = (options: CindediGidOptions = {}) =>
  function cindediGid<Value extends string>({value}: TypeGuardContext<Value>): RuleResult<Value> {
    let valid = true;
    let message =
      options.message ?? `The attribute must be a valid Cindedi Global Identifier (GID).`;
    try {
      const gid = parseGid(value);
      const {domain, entity} = options;
      if (domain != null && domain !== gid.domain) {
        valid = false;
        message = `The attribute must be a valid Cindedi Global Identifier (GID) with the domain ${domain}.`;
      }
      if (entity != null && entity !== gid.entity) {
        console.log('expected', entity, 'actual', gid);
        valid = false;
        message = `The attribute must be a valid Cindedi Global Identifier (GID) with the entity ${entity}.`;
      }
    } catch (_) {
      valid = false;
    }

    return createResult({value, valid, message});
  };
